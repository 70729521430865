/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Brand } from "../../Styled";

const routes = [
  {
    id: "#top",
    label: "Home",
    path: "/#top",
  },
  {
    id: "#wallet",
    label: "Wallet",
    path: "https://wallet.flake.exchange",
  },
  {
    id: "#services",
    label: "Services",
    path: "/#services",
  },
  {
    id: "#about",
    label: "About",
    path: "/#about",
  },
];

export default function Layout({ children }) {
  const location = useLocation();
  const [headerFixed, setHeaderFixed] = useState(!!window.scrollY);
  const [currentPath, setCurrentPath] = useState(location.hash || "#top");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setHeaderFixed(!!window.scrollY);

      routes.forEach((item) => {
        const element = document.querySelector(item.id);
        if (element && element.offsetTop <= window.scrollY + 10)
          setCurrentPath(item.id);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ["init"]);

  return (
    <>
      <header
        className="header-area header-sticky wow slideInDown"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
        style={{
          position: "fixed",
          background: headerFixed ? "white" : "transparent",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav
                className="main-nav"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Brand>
                  <div className="logo">
                    <img src={process.env.PUBLIC_URL + "/favicon.png"} alt="" />
                  </div>
                  <span>Flake</span>
                </Brand>

                <ul className="nav">
                  {routes.map((item) => (
                    <li key={item.id}>
                      <a
                        href={item.path}
                        className={
                          item.id.includes(currentPath) ? "active" : ""
                        }
                        onClick={() => setCurrentPath(item.id)}
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
                <a className="menu-trigger">
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {children}
      <footer id="newsletter" style={{ textAlign: "center" }}>
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  Join our mailing list to receive the news &amp; latest trends
                </h4>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <form id="search" action="#" method="GET">
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <fieldset>
                      <input
                        type="address"
                        name="address"
                        className="email"
                        placeholder="Email Address..."
                        autoComplete="on"
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <fieldset>
                      <button type="submit" className="main-button">
                        Subscribe Now <i className="fa fa-angle-right"></i>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget" style={{ height: "100%" }}>
                <h4 style={{ textAlign: "center" }}>Contact Us</h4>
                <p>
                  <a href="mailto:contact@flake.exchange">
                    contact@flake.exchange
                  </a>
                </p>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      float: "none",
                      maxWidth: 0,
                    }}
                  >
                    <li style={{ margin: "0 5px" }}>
                      <a href="#">Twitter</a>
                    </li>
                    <li style={{ margin: "0 5px" }}>
                      <a href="#">Telegram</a>
                    </li>
                    <li style={{ margin: "0 5px" }}>
                      <a href="#">Slack</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget" style={{ height: "100%" }}>
                <h4 style={{ textAlign: "center" }}>About Us</h4>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <ul>
                    <li>
                      <a href="/#top">Home</a>
                    </li>
                    <li>
                      <a href="#">Wallet</a>
                    </li>
                    <li>
                      <a href="/#services">Services</a>
                    </li>
                    <li>
                      <a href="/#about">About</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#">Team</a>
                    </li>
                    <li>
                      <a href="#">Whitepaper</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget" style={{ height: "100%" }}>
                <h4 style={{ textAlign: "center" }}>Useful Links</h4>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <ul>
                    <li>
                      <a href="#">Snowtrace</a>
                    </li>
                    <li>
                      <a href="#">Debank</a>
                    </li>
                    <li>
                      <a href="#">Programming</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget" style={{ height: "100%" }}>
                <h4 style={{ textAlign: "center", margin: 0 }}>
                  About Our Company
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Brand
                    style={{
                      alignItems: "center",
                      height: "auto",
                      padding: 0,
                    }}
                  >
                    <div className="logo">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/logowhite.png"
                        }
                        alt=""
                        style={{ margin: 0 }}
                      />
                    </div>
                    <span
                      style={{ padding: 0, paddingLeft: 2, color: "white" }}
                    >
                      Flake
                    </span>
                  </Brand>
                </div>
                <p></p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copyright-text">
                <p>Copyright © 2022 Flake Company. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
