import styled from "styled-components";

export const Brand = styled.div`
  display: flex;
  align-items: end;
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  height: 80px;
  padding-bottom: 5px;

  .logo {
    display: flex;
    width: 50px;
    min-width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 45px;
      border-radius: 45px;
    }
  }

  span {
    display: block;
    box-sizing: border-box;
    padding-left: 15px;
    font-size: 18px;
    color: #2a2a2a;
    white-space: nowrap;
    font-weight: bold;
    padding-bottom: 10px;
  }
`;
