/* eslint-disable jsx-a11y/anchor-is-valid */
import Icon from "../../Common/Icon";
import Layout from "../../Layout";

export default function Home() {
  return (
    <Layout>
      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div
                    className="left-content show-up header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>
                          Track All Your Crypto Portfolio And Auto Scale Your
                          DeFi Trading
                        </h2>
                        <p>
                          Base on a community-driven decentralized exchange for
                          Avalanche, Ethereum, Bsc, Polygon, Fantom assets with
                          fast settlement, low transaction fees, and manage
                          yours all prefered echange like Coinbase, Binance.
                          Flake brings you the best trading opportunities to
                          find and maximize your yield with the bot option.
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="white-button first-button scroll-to-section">
                          <a href="https://wallet.flake.exchange">
                            Open wallet
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <img src="assets/images/slider-dec.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div
                className="section-heading wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h4>
                  Amazing <em>Features</em> for you
                </h4>
                <img src="assets/images/heading-line-dec.png" alt="" />
                <p>
                  Flake offers a range of features that make it the best
                  environment to start trading devices
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="savings_outlined"></Icon>
                <h4>Portfolio Tracking</h4>
                <p>
                  The ability to track cryptocurrency investment performance.
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="trending_up"></Icon>
                <h4>Trading</h4>
                <p>The ability to buy and sell cryptocurrencies.</p>
                <div className="text-button">
                  <a> 🚀 Comming soon !</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="smart_toy"></Icon>
                <h4>Auto Trading</h4>
                <p>The ability to set custom automated trades.</p>
                <div className="text-button">
                  <a> 🚀 Comming soon !</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="agriculture"></Icon>
                <h4>Farming</h4>
                <p>
                  The ability to earn tokens by depositing liquidity inside
                  pool.
                </p>
                <div className="text-button">
                  <a> 🚀 Comming soon !</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "25px" }}>
          <div className="row">
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="business_center"></Icon>
                <h4>Staking</h4>
                <p>The ability to earn tokens by depositing liquidity token.</p>
                <div className="text-button">
                  <a> 🚀 Comming soon !</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="blur_on"></Icon>
                <h4>Multi Chain</h4>
                <p>
                  The ability to trade with Avalanche, Ethereum, Bsc, Polygon,
                  Fantom.
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item">
                <Icon className="icon" name="settings_input_composite"></Icon>
                <h4>Multi Exchange</h4>
                <p>
                  The ability to trade with Coinbase, Coinbase Pro, Binance,
                  Nicehash...
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="section-heading">
                <h4>About</h4>
                <img src="assets/images/heading-line-dec.png" alt="" />
                <p>
                  Flake is a decentralized exchange (DEX) which runs on
                  Avalanche, uses the same automated market-making (AMM) model
                  as Uniswap, features a native governance token called FKT that
                  is fully community distributed and is capable of trading all
                  tokens issued on Ethereum and Avalanche. In a crowded
                  marketplace with multiple contenders, Flake offers three
                  critically important benefits: fast and cheap trades,
                  community-driven development, and a fair and open token
                  distribution. First, Flake can finalize trades quickly and
                  cheaply. Since Flake is built on Avalanche, it enables users
                  to swap assets while enjoying sub-second transaction finality
                  and transaction fees as low as a few cents. Oftentimes, trades
                  on Flake will feel as fast as trades on centralized exchanges.
                  Second, beyond the significant performance upgrades to the
                  technical status quo, Flake is community-driven. The native
                  governance token, FKT, enables the community to drive the
                  development of the product in full. Third, but not least,
                  Flake features a 100% community-focused token distribution
                  model, meaning that all tokens are distributed directly to the
                  community, without any allocations to team, advisors,
                  investors, or insiders. Users of existing AMMs, such as
                  Uniswap and Sushiswap, are already familiar with their
                  mechanism of action. Therefore, the rest of this post does not
                  discuss how Flake achieves its trading capabilities. Instead,
                  we discuss the FKT token in more detail, including its
                  distribution mechanism and governance rules.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image">
                <img src="assets/images/about-right-dec.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
