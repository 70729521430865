import { BrowserRouter as Router } from "react-router-dom";
import Nav from "./Nav";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router basename={process.env.REACT_APP_PUBLIC_BASENAME || "/"}>
        <Nav />
      </Router>
    </div>
  );
}

export default App;
